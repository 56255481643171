import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a485f10"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Add Group")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          modelValue: _ctx.queryString,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryString) = $event)),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.findGroups()}, ["enter"]))
        }, null, 8, ["modelValue"]),
        (!_ctx.filteredFacilityGroupsByType.null && _ctx.isSearching)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(_ctx.translate("No facility groups found")), 1)
            ]))
          : (_openBlock(), _createElementBlock("form", {
              key: 1,
              onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.updateGroups && _ctx.updateGroups(...args)), ["enter"]))
            }, [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFacilityGroupsByType, (groups, typeId) => {
                    return (_openBlock(), _createBlock(_component_ion_item_group, { key: typeId }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item_divider, { color: "light" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFacilityGroupTypeDesc(typeId)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groups, (group) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: group.facilityGroupId
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_checkbox, {
                                checked: _ctx.isFacilityGroupLinked(group.facilityGroupId),
                                onIonChange: ($event: any) => (_ctx.updateGroupsForFacility(group.facilityGroupId))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(group.facilityGroupName), 1)
                                ]),
                                _: 2
                              }, 1032, ["checked", "onIonChange"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ], 32)),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, { onClick: _ctx.updateGroups }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}